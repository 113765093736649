import React from 'react';

const InvalidTransactionScreen = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        gap: 18,
        fontSize: 16,
        padding: '0 20px',
      }}
    >
      <p style={{ fontSize: 18, fontWeight: 600 }}>
        This payment link may be broken or expired
      </p>
      <img
        style={{
          width: '80%',
          maxWidth: '150px',
          height: 'auto',
        }}
        src="error.png"
        alt="Zinari error occured"
      />
      <p>Please contact your vendor</p>
      <img
        style={{
          width: '80%',
          maxWidth: '80px',
          height: 'auto',
        }}
        src="https://pay.zinari.io/logoPurple.webp"
        alt="Zinari error occured"
      />
    </div>
  );
};

export default InvalidTransactionScreen;
