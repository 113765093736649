import React from 'react';

import Spinner, { SpinnerVariant } from '../Spinner';

const AppLoader = ({ size }: { size?: number }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: size || '40vh',
        justifyContent: 'center',
      }}
    >
      <Spinner variant={SpinnerVariant.WAVE} size={size} />
    </div>
  );
};

export default AppLoader;
