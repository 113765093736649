// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { constants, createDecipheriv, privateDecrypt } from 'crypto-browserify';

const payLinkPrivateKey = (process.env.REACT_APP_PAYLINK_PRIVATE_KEY || '')
  .split('\n')
  .reduce((acc, curr) => {
    acc += `${curr}\n`;
    return acc;
  }, ``) as string;
const payLinkKeyEncoding = process.env.REACT_APP_PAYLINK_KEY_ENCODING;
const payLinkOutputEncoding = process.env.REACT_APP_PAYLINK_OUTPUT_ENCODING;
const payLinkCipherAlgorithm = process.env
  .REACT_APP_PAYLINK_CIPHER_ALGORITHM as any;

export const decryptAppCredentials = (encryptedData: string): any => {
  try {
    const pkg = JSON.parse(encryptedData) as any;

    // Decrypt the AES key with the RSA private key
    const aesKey = privateDecrypt(
      { key: payLinkPrivateKey, padding: constants.RSA_PKCS1_OAEP_PADDING },
      Buffer.from(pkg.key, payLinkKeyEncoding as any),
    );

    // Decrypt the data with the AES key
    const iv = Buffer.from(pkg.iv, payLinkKeyEncoding as any);
    const decipher = createDecipheriv(payLinkCipherAlgorithm, aesKey, iv);
    let decryptedData = decipher.update(
      pkg.data,
      payLinkKeyEncoding as any,
      payLinkOutputEncoding as any,
    );

    decryptedData += decipher.final(payLinkOutputEncoding as any);

    return JSON.parse(decryptedData);
  } catch (e: any) {
    console.log('error', e);
    throw new Error(e);
  }
};
